import React, { FC } from "react";
import { SVGProps } from "react";

const CloseIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width={24} height={24} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.0495 3.0495C0.316832 5.78218 0.316832 10.2178 3.0495 12.9505C5.78218 15.6832 10.2178 15.6832 12.9505 12.9505C15.6832 10.2178 15.6832 5.78218 12.9505 3.0495C10.2178 0.316832 5.78218 0.316832 3.0495 3.0495ZM10.9703 6.0198L8.9901 8L10.9703 9.9802L9.9802 10.9703L8 8.9901L6.0198 10.9703L5.0297 9.9802L7.0099 8L5.0297 6.0198L6.0198 5.0297L8 7.0099L9.9802 5.0297L10.9703 6.0198Z"
      fill={props.fill}
    />
  </svg>
);
export default CloseIcon;
