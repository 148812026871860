import React, { FC } from "react";

const LogoutIcon: FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2399 4.00098C13.0788 4.82035 13.6501 5.86419 13.8814 7.00053C14.1128 8.13687 13.9939 9.31467 13.5397 10.385C13.0855 11.4554 12.3165 12.3702 11.3298 13.0138C10.3432 13.6574 9.18319 14.001 7.99658 14.001C6.80998 14.001 5.65002 13.6574 4.66337 13.0138C3.67671 12.3702 2.90768 11.4554 2.45349 10.385C1.99931 9.31467 1.88038 8.13687 2.11173 7.00053C2.34308 5.86419 2.91433 4.82035 3.75325 4.00098"
        stroke="black"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 2.00098V8.00098" stroke="black" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default LogoutIcon;
