import React, { FC, useCallback, useEffect, useRef } from "react";
import TopBand from "./TopBand";
import "./App.css";

const App: FC<{ appAcronym: string }> = ({ appAcronym }) => {
  const postMessageHandler = useCallback(
    (e: MessageEvent) => {
      if (e.data?.messageType === "LOGOUT") {
        const form = document.createElement("form");
        form.method = "post";
        form.action = `${window.__PATH__?.appUrls.authorization || "/"}Logout`;
        const field = document.createElement("input");
        field.type = "hidden";
        field.name = "acronym";
        field.value = appAcronym || "";
        form.append(field);
        document.body.append(form);
        form.submit();
      }
    },
    [appAcronym]
  );

  useEffect(() => {
    window.addEventListener("message", postMessageHandler);
    return () => {
      window.removeEventListener("message", postMessageHandler);
    };
  }, [postMessageHandler]);

  const params = useRef(new URLSearchParams(window.location.search)).current;
  useEffect(() => {
    if (params.get("hideTopBand") === "true") {
      document.documentElement.style.setProperty("--topband-height", "0px");
    } else {
      document.documentElement.style.setProperty("--topband-height", "40px");
    }
  }, [params]);


  return <TopBand appAcronym={appAcronym} />;
};

export default App;
