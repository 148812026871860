import React, { FC } from "react";
import { LogoutIcon } from "../Icons";
import { IUserDetails } from "../interfaces";
import styles from "./styles.module.css";

interface IProps {
  user: IUserDetails;
  acronym: string | undefined;
}

const Profile: FC<IProps> = ({ acronym, user }) => {
  const { email, firstName, lastName, avatar } = user;

  const onLogoutButtonClick = () => {
    const message = { messageType: "LOGOUT" };
    if (window.top) {
      window.top.postMessage(message, "*");
    } else {
      window.postMessage(message, "*");
    }
  };

  return (
    <div className={styles.profile_logout}>
      <div className={styles.logedin_as}>
        <div className={styles.user_name_type}>
          <div className={styles.user_name}>{`${firstName} ${lastName}`}</div>
          <div className={styles.user_type}>{email}</div>
        </div>
        <div className={styles.user_initial}>{avatar ? <img src={avatar} alt="" /> : firstName[0]}</div>
      </div>

      <div className={styles.buttons_container}>
      <button type="button" onClick={onLogoutButtonClick} className={styles.logout_button}>
        <LogoutIcon />
        Logout
      </button>
      </div>
    </div>
  );
};

export default Profile;
