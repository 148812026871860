import * as React from "react";
import { SVGProps } from "react";
const HelpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={24} height={24} rx={12} fill={props.fill} />
    <path
      d="M12.0284 5.25903C13.3124 5.25903 14.3384 5.59503 15.1064 6.26703C15.8864 6.93903 16.2764 7.88703 16.2764 9.11103C16.2764 10.239 15.9044 11.121 15.1604 11.757C14.4284 12.381 13.4564 12.699 12.2444 12.711L12.1544 14.061H9.45436L9.36436 10.713H10.4444C11.3684 10.713 12.0704 10.599 12.5504 10.371C13.0424 10.143 13.2884 9.72903 13.2884 9.12903C13.2884 8.70903 13.1744 8.37903 12.9464 8.13903C12.7184 7.89903 12.4004 7.77903 11.9924 7.77903C11.5604 7.77903 11.2244 7.90503 10.9844 8.15703C10.7444 8.39703 10.6244 8.72703 10.6244 9.14703H7.72636C7.70236 8.41503 7.85236 7.75503 8.17636 7.16703C8.51236 6.57903 9.00436 6.11703 9.65236 5.78103C10.3124 5.43303 11.1044 5.25903 12.0284 5.25903ZM10.8404 18.741C10.3004 18.741 9.85636 18.585 9.50836 18.273C9.17236 17.949 9.00436 17.553 9.00436 17.085C9.00436 16.605 9.17236 16.203 9.50836 15.879C9.85636 15.555 10.3004 15.393 10.8404 15.393C11.3684 15.393 11.8004 15.555 12.1364 15.879C12.4844 16.203 12.6584 16.605 12.6584 17.085C12.6584 17.553 12.4844 17.949 12.1364 18.273C11.8004 18.585 11.3684 18.741 10.8404 18.741Z"
      fill={props.fill}
    />
    <path
      d="M12.0284 5.25903C13.3124 5.25903 14.3384 5.59503 15.1064 6.26703C15.8864 6.93903 16.2764 7.88703 16.2764 9.11103C16.2764 10.239 15.9044 11.121 15.1604 11.757C14.4284 12.381 13.4564 12.699 12.2444 12.711L12.1544 14.061H9.45436L9.36436 10.713H10.4444C11.3684 10.713 12.0704 10.599 12.5504 10.371C13.0424 10.143 13.2884 9.72903 13.2884 9.12903C13.2884 8.70903 13.1744 8.37903 12.9464 8.13903C12.7184 7.89903 12.4004 7.77903 11.9924 7.77903C11.5604 7.77903 11.2244 7.90503 10.9844 8.15703C10.7444 8.39703 10.6244 8.72703 10.6244 9.14703H7.72636C7.70236 8.41503 7.85236 7.75503 8.17636 7.16703C8.51236 6.57903 9.00436 6.11703 9.65236 5.78103C10.3124 5.43303 11.1044 5.25903 12.0284 5.25903ZM10.8404 18.741C10.3004 18.741 9.85636 18.585 9.50836 18.273C9.17236 17.949 9.00436 17.553 9.00436 17.085C9.00436 16.605 9.17236 16.203 9.50836 15.879C9.85636 15.555 10.3004 15.393 10.8404 15.393C11.3684 15.393 11.8004 15.555 12.1364 15.879C12.4844 16.203 12.6584 16.605 12.6584 17.085C12.6584 17.553 12.4844 17.949 12.1364 18.273C11.8004 18.585 11.3684 18.741 10.8404 18.741Z"
      fill="white"
    />
  </svg>
);
export default HelpIcon;
