import React, { useState, useEffect, useRef, FC, useCallback } from "react";
import style from "./styles.module.css";
import Profile from "./Profile";
import { IApplication, IPostMessage } from "./interfaces";
import Help from "../Help/Help";
import J36Logo from "./Icons/J36Logo";
import J36LogoHover from "./Icons/J36LogoHover";

const searchParams = new URLSearchParams(window.location.search);
const hideTopBand = searchParams.get("hideTopBand") === "true";

const appFilter = (appAcronym: string | undefined) => (app: IApplication) =>
  app.acronym?.toLowerCase() === appAcronym?.toLowerCase();

interface IProps {
  /**
   * accronym of the application. Example: bi, pd, dm, flows
   */
  appAcronym: string;
}

const TopBand: FC<IProps> = ({ appAcronym }) => {
  const [showProfile, setShowProfile] = useState<boolean>(false);
  const [isLoadedFromDashboard, setIsLoadedFromDashboard] = useState<boolean>(false);
  const profileRef = useRef<HTMLDivElement | null>(null);
  const userDetails = useRef(window.__USER_SESSION__).current;
  const currentApplication = useRef(userDetails?.applicationList?.find(appFilter(appAcronym))).current;

  /* -------------------------------------------------------------- */
  /* -------------------------------------------------------------- */
  /* -------------------------------------------------------------- */

  // close popups on outside click
  const mouseClickHandler = useCallback(
    (e: any) => {
      if (showProfile && !profileRef.current?.contains(e.target)) {
        setTimeout(() => {
          setShowProfile(false);
        }, 200);
      }
    },
    [showProfile]
  );

  useEffect(() => {
    window.addEventListener("mousedown", mouseClickHandler);
    return () => {
      window.removeEventListener("mousedown", mouseClickHandler);
    };
  }, [mouseClickHandler]);

  // request to detect source
  useEffect(() => {
    const message: IPostMessage = {
      messageType: "APPLICATION_SOURCE_REQUEST",
      payload: {},
    };
    window.parent.postMessage(message, "*");
  }, []);

  // response listener to detect source
  const postMessageHandler = useCallback((e: MessageEvent<IPostMessage>) => {
    if (e.data?.messageType === "APPLICATION_SOURCE_RESPONSE" && e.data?.payload?.source === "dashboard") {
      // TODO: to be implemented latercod
      setIsLoadedFromDashboard(true);
    } else if (e.data?.messageType === "CLOSE_PROFILE_POPUP") {
      setShowProfile(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", postMessageHandler);
    return () => {
      window.removeEventListener("message", postMessageHandler);
    };
  }, [postMessageHandler]);

  /* -------------------------------------------------------------- */
  /* -------------------------------------------------------------- */
  /* -------------------------------------------------------------- */

  // profile popup
  const toggleProfile = () => {
    setShowProfile(!showProfile);
  };

  /* -------------------------------------------------------------- */
  /* -------------------------------------------------------------- */
  /* -------------------------------------------------------------- */

  // set app color in app name as important

  useEffect(() => {
    document.documentElement.style.setProperty("--topband-app-color", currentApplication?.bgColor || "#888");
  }, [currentApplication]);

  /* -------------------------------------------------------------- */
  /* -------------------------------------------------------------- */
  /* -------------------------------------------------------------- */

  // show dashboard
  const onLogoClick = () => {
    if (isLoadedFromDashboard && currentApplication && appAcronym !== "dashboard" && appAcronym !== "login") {
      const message: IPostMessage = {
        messageType: "HIDE_ACTIVE_FRAME",
        payload: {},
      };
      window.parent.postMessage(message, "*");
    }
  };

  /* -------------------------------------------------------------- */
  /* -------------------------------------------------------------- */
  /* -------------------------------------------------------------- */

  // rendering
  if (hideTopBand) {
    return null;
  } else {
    return (
      <>
        <div className={style.app_band}>
          <div className={style.j36_app}>
            <div
              className={`${style.j36_logo}  ${
                appAcronym && appAcronym !== "dashboard" && appAcronym !== "login" ? style.j36_logo_app : ""
              }`}
              onClick={onLogoClick}
            >
              <span className={style.j36_logo_default}>
                <J36Logo />
              </span>
              <span className={style.j36_logo_hover}>
                <J36LogoHover />
              </span>
            </div>
            {appAcronym && appAcronym !== "dashboard" && appAcronym !== "login" ? (
              <a
                href={`/${currentApplication?.acronym || ""}`}
                className={style.app_name_link}
                style={{ backgroundColor: currentApplication?.bgColor || "#000" }}
              >
                <span className={style.txt_01}>{currentApplication?.name || ""}</span>
                <span className={style.txt_02}>{currentApplication?.name || ""}</span>
              </a>
            ) : null}
          </div>

          <div className={style.right_Sec}>
            {userDetails ? (
              <>
                <div className={style.circle} onClick={toggleProfile}>
                  <div className={style.circle_text}>
                    {userDetails.avatar ? <img src={userDetails.avatar} alt="" /> : userDetails.firstName[0]}
                  </div>
                </div>
                <div className={style.user_name} onClick={toggleProfile}>
                  <div className={style.user_text}>{`${userDetails?.firstName} ${userDetails?.lastName}`}</div>
                </div>

                {showProfile ? (
                  <div className={style.profile_logout_wrap} ref={profileRef}>
                    <Profile user={userDetails} acronym={currentApplication?.acronym || ""} />
                  </div>
                ) : null}
                <Help appAcronym={appAcronym} />
              </>
            ) : null}
          </div>
        </div>
      </>
    );
  }
};

export default TopBand;
