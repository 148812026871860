import React, { FC, useEffect, useRef, useState } from "react";
import HelpIcon from "../TopBand/Icons/HelpIcon";
import styles from "./Help.module.css";
import CloseIcon from "../TopBand/Icons/CloseIcon";
interface IProps {
  appAcronym: string | undefined;
}

const Help: FC<IProps> = ({ appAcronym }) => {
  const userDetails = useRef(window.__USER_SESSION__).current;

  const currentApplication = useRef(
    (userDetails?.applicationList || []).find((app) => app.acronym?.toLowerCase() === appAcronym?.toLowerCase())
  ).current;

  const helpUrl = useRef<string>(
    (() => {
      let url = "";
      if (
        currentApplication &&
        currentApplication.acronym !== "dashboard" &&
        currentApplication.isKnowledgeItemEnabled
      ) {
        url =
          window.__PATH__?.appUrls.knowledgeViewer +
          `?search=true&keyword=&group=&tags=&categories=&applicationId=${currentApplication._id}&hideTopBand=true`;
      }
      return url;
    })()
  ).current;

  const [showHelp, setShowHelp] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const onHelpClick = () => {
    if (!isInitialized) {
      setIsInitialized(true);
    }
    setShowHelp(!showHelp);
    window.postMessage({ messageType: "CLOSE_PROFILE_POPUP" }, "*");
  };

  useEffect(() => {
    Object.defineProperty(window, "closeSecondaryFrame", {
      value: () => {
        setShowHelp(false);
      },
      configurable: true,
    });
  }, []);

  if (helpUrl) {
    return (
      <>
        {isInitialized ? (
          <iframe title="Help" src={helpUrl} className={`${styles.help_frame} ${showHelp ? "" : styles.hidden}`} />
        ) : null}
        <span className={styles.container} onClick={onHelpClick}>
          {showHelp ? (
            <CloseIcon fill={currentApplication?.bgColor || "#000000"} />
          ) : (
            <HelpIcon fill={currentApplication?.bgColor || "#000000"} />
          )}
        </span>
      </>
    );
  } else {
    return null;
  }
};

export default Help;
