import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("topBandRoot");
if (container) {
  const root = ReactDOM.createRoot(container);

  root.render(
    <React.StrictMode>
      <App appAcronym={container.getAttribute("data-app-acronym") || ""} />
    </React.StrictMode>
  );
}

reportWebVitals();
