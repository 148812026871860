import React, { FC, SVGProps } from "react";

const J36Logo: FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width="30" height="12" viewBox="0 0 30 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 8.07864H2.7763C2.78188 8.52554 2.90839 8.88377 3.1561 9.14773C3.40382 9.41428 3.73509 9.54616 4.1498 9.54616C4.57539 9.54616 4.90114 9.41428 5.12464 9.15303C5.34825 8.88914 5.45855 8.50402 5.45855 8.00058V0.61377H8.35627V8.02482C8.35627 9.29044 7.98735 10.2706 7.25477 10.9628C6.52235 11.6547 5.5017 11.9996 4.19825 11.9996C2.91371 11.9996 1.89314 11.6494 1.13646 10.9465C0.379595 10.2437 0 9.28778 0 8.07864Z"
        fill="white"
      />
      <path
        d="M13.1642 6.87252V4.87711H14.5593C15.0441 4.87711 15.4318 4.75042 15.728 4.50271C16.0215 4.25228 16.1696 3.91834 16.1696 3.50363C16.1696 3.08892 16.0242 2.76031 15.7307 2.52063C15.4398 2.28095 15.0414 2.16261 14.5351 2.16261C14.0421 2.16261 13.6383 2.29715 13.3286 2.56908C13.0188 2.83837 12.8545 3.19925 12.8385 3.64639H10.2127C10.2343 2.53151 10.6383 1.64543 11.4299 0.985716C12.2217 0.328615 13.2801 0 14.5996 0C15.8599 0 16.886 0.288203 17.6749 0.864533C18.464 1.44086 18.8598 2.18948 18.8598 3.10784C18.8598 3.76222 18.6606 4.32501 18.2649 4.79621C17.8661 5.27024 17.3465 5.56906 16.7054 5.69027V5.83835C17.4893 5.92192 18.1139 6.20462 18.5799 6.68661C19.0457 7.1687 19.2772 7.77462 19.2772 8.5044C19.2772 9.53585 18.8383 10.3761 17.9604 11.025C17.0826 11.6767 15.9461 12 14.5511 12C13.2207 12 12.1437 11.666 11.3169 10.9954C10.4902 10.325 10.0513 9.43092 10 8.31604H12.7602C12.7818 8.75225 12.957 9.09693 13.2855 9.34465C13.6141 9.595 14.053 9.72171 14.5996 9.72171C15.1141 9.72171 15.5314 9.58704 15.8545 9.32307C16.1749 9.05644 16.3339 8.71183 16.3339 8.28359C16.3339 7.8473 16.1776 7.50269 15.8599 7.25227C15.5448 6.99905 15.1112 6.87252 14.5593 6.87252H13.1642Z"
        fill="#AAAAAA"
      />
      <path
        d="M25.4465 12.0001C24.6385 12.0001 23.9114 11.844 23.2653 11.5262C22.6215 11.2112 22.0937 10.7533 21.6843 10.1554C20.906 9.12947 20.5156 7.76134 20.5156 6.05143C20.5156 4.18524 20.9572 2.71213 21.8433 1.62949C22.7265 0.549722 23.9303 0.00830078 25.4545 0.00830078C26.6017 0.00830078 27.5847 0.312679 28.4034 0.921281C29.2192 1.52722 29.7066 2.31075 29.8576 3.26688H27.0246C26.9356 2.95718 26.7471 2.70946 26.4616 2.5209C26.1736 2.33506 25.837 2.24073 25.4465 2.24073C24.749 2.24073 24.1942 2.57208 23.7876 3.23177C23.3782 3.89173 23.2005 4.74802 23.2544 5.80084H23.4051C23.6557 5.26766 24.0299 4.85846 24.5255 4.57299C25.0183 4.28753 25.5946 4.14208 26.2516 4.14208C27.3314 4.14208 28.2255 4.50031 28.9339 5.21118C29.6448 5.92489 30.0003 6.82162 30.0003 7.90679C30.0003 9.10524 29.5747 10.088 28.7212 10.8529C27.8702 11.6178 26.7795 12.0001 25.4465 12.0001ZM24.1484 9.25598C24.4931 9.6033 24.9186 9.77582 25.425 9.77582C25.9284 9.77582 26.354 9.6033 26.7013 9.25869C27.0488 8.914 27.2238 8.494 27.2238 7.99299C27.2238 7.48671 27.0514 7.06668 26.7068 6.72736C26.362 6.38808 25.9339 6.21842 25.425 6.21842C24.9133 6.21842 24.4877 6.38542 24.1458 6.72186C23.8037 7.05851 23.6341 7.47872 23.6341 7.97681C23.6341 8.48312 23.8065 8.90871 24.1484 9.25598Z"
        fill="#AAAAAA"
      />
    </svg>
  );
};

export default J36Logo;
